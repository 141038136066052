export const getQueryStringParam = (param) => {
  if ('URLSearchParams' in window) {
    const paramValueFromURL = new URLSearchParams(window.location.search).get(param);
    return paramValueFromURL;
  }

  const queryParams = window.location.search.substring(1);
  const queryKeyValues = queryParams.split("&");

  for (const keyValue of queryKeyValues) {
    const [key, value] = keyValue.split("=");
    if (key === param) {
      return decodeURIComponent(value);
    }
  }
  return "";
}
